"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
/* IMPORT CORE MODULES */
var react_1 = require("react");
// ✔ create user's badge component
/**
 * #### User's Badge Component ####
 * @description This component can render single user's badge and show badge name when hover.
 * @param { UserBadgeProps } props - What is user's badge component property?
 * @returns { React.ReactNode } User's badge component
 *
 * @example
 * ```typescriptreact
 * import { UserBadge } from '@/components/molecules`
 * <UserBadge name="BADGE_NAME" image="BADGE_IMAGE" />
 * ```
 *
 * @example
 * ```typescriptreact
 * import { User } from '@/components/molecules`
 * <User.Badge name="BADGE_NAME" image="BADGE_IMAGE" />
 * ```
 *
 * @version 1.0.0
 * @since 10.3.0
 * @author Wunna
 */
function UserBadge(props) {
    var name = props.name, image = props.image;
    var _a = __read((0, react_1.useState)(false), 2), isBadgeTooltipOpen = _a[0], setIsBadgeTooltipOpen = _a[1];
    // ✔ create hover badge handler function
    /**
     * ### Hover Badge Handler Function ###
     * @description This function can toggle when user hover badge.
     */
    var hoverBadgeHandler = (0, react_1.useCallback)(function () {
        setIsBadgeTooltipOpen(function (prevIsBadgeTooltipOpen) { return !prevIsBadgeTooltipOpen; });
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-relative tw-cursor-pointer', onMouseEnter: hoverBadgeHandler, onMouseLeave: hoverBadgeHandler, children: [isBadgeTooltipOpen && ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-absolute tw-bottom-full tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-2 tw-whitespace-nowrap tw-rounded-md tw-bg-background-6 tw-px-3 tw-py-1.5 tw-text-xs', children: [name, (0, jsx_runtime_1.jsx)("div", { className: 'tw-absolute tw-left-1/2 tw-top-full tw-h-0 tw-w-0 -tw-translate-x-1/2 tw-border-l-[5px] tw-border-r-[5px] tw-border-t-[7.5px] tw-border-l-transparent tw-border-r-transparent tw-border-t-background-6' })] })), (0, jsx_runtime_1.jsx)("img", { className: 'tw-h-3 tw-w-3 tw-rounded-full', src: image || 'https://genesiv.com/img/tiers_placeholder.png' })] }));
}
exports.default = UserBadge;
