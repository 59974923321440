"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePathname = exports.useWindowSize = exports.useDebounce = void 0;
var react_1 = require("react");
/**
 * useDebounce hook for searching
 */
function useDebounce(value, delay) {
    if (delay === void 0) { delay = 200; }
    var _a = __read((0, react_1.useState)(value), 2), debouncedValue = _a[0], setDebouncedValue = _a[1];
    (0, react_1.useEffect)(function () {
        var timer = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        return function () {
            clearTimeout(timer);
        };
    }, [value, delay]);
    return debouncedValue;
}
exports.useDebounce = useDebounce;
/**
 * Hook to get window Width and Height when resizing
 * @example
 * const { width, height } = useWindowSize();
 */
var useWindowSize = function () {
    var _a = __read((0, react_1.useState)({
        width: 0,
        height: 0,
    }), 2), windowSize = _a[0], setWindowSize = _a[1];
    (0, react_1.useEffect)(function () {
        var handleResize = function () {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return windowSize;
};
exports.useWindowSize = useWindowSize;
/**
 * NextJS alike hook to listen for changes in pathname
 * @example
 * const pathname = usePathname();
 */
function usePathname() {
    var _a = __read((0, react_1.useState)(window.location.pathname), 2), pathname = _a[0], setPathname = _a[1];
    (0, react_1.useEffect)(function () {
        // Handler to update pathname state
        var handleLocationChange = function () {
            setPathname(window.location.pathname);
        };
        // Override pushState and replaceState to dispatch custom events
        var originalPushState = window.history.pushState;
        var originalReplaceState = window.history.replaceState;
        window.history.pushState = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            originalPushState.apply(this, args);
            window.dispatchEvent(new Event('pushstate'));
        };
        window.history.replaceState = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            originalReplaceState.apply(this, args);
            window.dispatchEvent(new Event('replacestate'));
        };
        // Listen for popstate, pushstate, and replacestate events
        window.addEventListener('popstate', handleLocationChange);
        window.addEventListener('pushstate', handleLocationChange);
        window.addEventListener('replacestate', handleLocationChange);
        // Cleanup event listeners
        return function () {
            window.removeEventListener('popstate', handleLocationChange);
            window.removeEventListener('pushstate', handleLocationChange);
            window.removeEventListener('replacestate', handleLocationChange);
        };
    }, []);
    return pathname;
}
exports.usePathname = usePathname;
