"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var checkbox_1 = require("@/components/ui/checkbox");
var utils_1 = require("@/lib/utils");
var redemption_store_state_1 = require("@/providers/recoil/redemption-store-state");
var react_1 = require("react");
var react_beautiful_dnd_1 = require("react-beautiful-dnd");
var recoil_1 = require("recoil");
/**
 * Redemption Accorion with DND to be used in Redemption Store
 */
var RedemptionAccordion = function (_a) {
    var rewards = _a.rewards, category = _a.category, handleCategoryClick = _a.handleCategoryClick, handleRewardInCategoryClick = _a.handleRewardInCategoryClick, children = _a.children, isOpen = _a.isOpen, toggleAccordion = _a.toggleAccordion, dragSnapshot = _a.dragSnapshot, selected = _a.selected, isArchived = _a.isArchived;
    var _b = __read((0, recoil_1.useRecoilState)(redemption_store_state_1.SelectedCategoriesState), 2), selectedCategories = _b[0], setSelectedCategories = _b[1];
    var _c = __read((0, recoil_1.useRecoilState)(redemption_store_state_1.SelectedRewardsState), 2), selectedRewards = _c[0], setSelectedRewards = _c[1];
    var isSelected = selectedCategories === null || selectedCategories === void 0 ? void 0 : selectedCategories.includes(category._id);
    var transitionData = (0, react_1.useMemo)(function () {
        var defaultData = {
            duration: '300ms',
            timingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
        };
        return defaultData;
    }, []);
    var filterArrayByIds = function (array, ids) {
        return array.filter(function (item) { return !ids.includes(item); });
    };
    var handleChange = function (_id, isChecked) {
        setSelectedCategories(function (prev) { return (isChecked ? __spreadArray(__spreadArray([], __read(prev), false), [_id], false) : prev.filter(function (id) { return id !== _id; })); });
        var foundRewards = rewards === null || rewards === void 0 ? void 0 : rewards.filter(function (r) { return r.categoryId === _id; });
        if (foundRewards && (foundRewards === null || foundRewards === void 0 ? void 0 : foundRewards.length) > 0) {
            var ids = foundRewards === null || foundRewards === void 0 ? void 0 : foundRewards.map(function (r) { return r._id; });
            var filteredArray = filterArrayByIds(selectedRewards, ids);
            setSelectedRewards(isChecked ? __spreadArray(__spreadArray([], __read(selectedRewards), false), __read(ids), false) : filteredArray);
        }
    };
    return ((0, jsx_runtime_1.jsx)(react_beautiful_dnd_1.Droppable, { droppableId: category._id, type: 'ITEM', children: function (provided) {
            return ((0, jsx_runtime_1.jsxs)("div", __assign({ ref: provided.innerRef }, provided.droppableProps, { className: 'tw-mb-1.5 tw-w-full', children: [(0, jsx_runtime_1.jsxs)("div", { id: 'accordion-header', className: 'tw-group tw-flex tw-w-full tw-items-center tw-gap-1 tw-pl-5', children: [(0, jsx_runtime_1.jsx)(checkbox_1.Checkbox, { checked: isSelected, onCheckedChange: function (checked) { return handleChange(category._id, checked); }, className: (0, utils_1.cn)('tw-absolute tw-left-0 tw-top-2.5 tw-hidden group-hover:tw-block', isSelected && '!tw-block') }), (0, jsx_runtime_1.jsx)("button", { onClick: toggleAccordion, children: (0, jsx_runtime_1.jsx)(General_1.CollapseArrow, { width: '20px', height: '20px', className: (0, utils_1.cn)(!isOpen ? 'tw-rotate-0' : 'tw-rotate-180', 'tw-transform-gpu tw-transition-all tw-duration-300 tw-ease-in-out') }) }), (0, jsx_runtime_1.jsxs)("div", { className: (0, utils_1.cn)('tw-flex tw-w-full tw-cursor-pointer tw-justify-between tw-rounded tw-bg-background-5 tw-p-2 hover:tw-bg-background-7', dragSnapshot.isDragging && 'tw-border tw-border-divider-lines-1 tw-bg-background-7', isSelected && '!tw-bg-background-7', selected && '!tw-bg-background-3'), onClick: function () { return handleCategoryClick(category); }, children: [(0, jsx_runtime_1.jsx)("p", { children: category.name }), !isArchived ? ((0, jsx_runtime_1.jsx)("div", { className: 'tw-flex tw-items-center tw-gap-2', children: (0, jsx_runtime_1.jsx)("button", { className: 'tw-cursor-pointer', onClick: function (event) { return handleRewardInCategoryClick(event, category); }, children: (0, jsx_runtime_1.jsx)(General_1.AddCircleSVG, { className: 'tw-stroke-text-4 hover:tw-stroke-text-3' }) }) })) : null] })] }), (0, jsx_runtime_1.jsxs)("div", { id: 'accordion-body', style: {
                            transitionProperty: 'all',
                            overflow: 'hidden',
                            transitionDuration: transitionData.duration,
                            transitionTimingFunction: transitionData.timingFunction,
                        }, className: "".concat(isOpen ? 'tw-max-h-max' : 'tw-max-h-0'), children: [children, " ", provided.placeholder] })] })));
        } }));
};
exports.default = RedemptionAccordion;
