"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var molecules_1 = require("@/components/molecules");
var chat_container_utils_1 = require("@/lib/utils/chat-container-utils");
var react_1 = require("react");
var angular_util_1 = require("../helpers/angular-util");
var ChatContainer = function (_a) {
    var _this = this;
    var angularChild = _a.angularChild, mCount = _a.mCount, initialCommentsData = _a.initialCommentsData, chatloaded = _a.chatloaded, loadingMore = _a.loadingMore, commentsData = _a.commentsData, goingToParent = _a.goingToParent, selectedChannel = _a.selectedChannel, isActivityPage = _a.isActivityPage;
    var hasMore = mCount > 0;
    var _b = __read((0, react_1.useState)(''), 2), detectedId = _b[0], setDetectedId = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), isFetching = _c[0], setIsFetching = _c[1];
    var _d = __read((0, react_1.useState)(''), 2), type = _d[0], setType = _d[1];
    var scrollableDivRef = (0, react_1.useRef)(null);
    /** fetch data with `before` */
    var loadMore = (0, react_1.useCallback)(function (id) {
        if (goingToParent)
            return function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/];
            }); }); };
        return function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        delete chat_container_utils_1.scrollFunctions[id];
                        return [4 /*yield*/, window.loadMoreMessage('before', id)];
                    case 1:
                        _a.sent();
                        setType('before');
                        setDetectedId(id);
                        return [2 /*return*/];
                }
            });
        }); };
    }, []);
    /** fetch data with `after` */
    var loadPrevious = (0, react_1.useCallback)(function (id) {
        if (goingToParent)
            return function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/];
            }); }); };
        return function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        delete chat_container_utils_1.scrollFunctions[id];
                        return [4 /*yield*/, window.loadMoreMessage('after', id)];
                    case 1:
                        _a.sent();
                        setType('after');
                        setDetectedId(id);
                        return [2 /*return*/];
                }
            });
        }); };
    }, []);
    // scrolling back after data fetch with `before` and `after`
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d;
        if (goingToParent)
            return;
        if (!loadingMore && commentsData) {
            if (type === 'before') {
                (0, chat_container_utils_1.triggerElement)((_a = commentsData[commentsData.length - 1]) === null || _a === void 0 ? void 0 : _a._id, loadMore((_b = commentsData[commentsData.length - 1]) === null || _b === void 0 ? void 0 : _b._id));
                (0, chat_container_utils_1.scrollToElement)(detectedId, 'top');
                setIsFetching(false);
            }
            else if (type === 'after') {
                (0, chat_container_utils_1.triggerElement)((_c = commentsData[0]) === null || _c === void 0 ? void 0 : _c._id, loadPrevious((_d = commentsData[0]) === null || _d === void 0 ? void 0 : _d._id));
                (0, chat_container_utils_1.scrollToElement)(detectedId, 'bottom');
                setIsFetching(false);
            }
        }
    }, [loadingMore, commentsData, detectedId, type]);
    // scrolling back after data fetch with `around`
    (0, react_1.useEffect)(function () {
        var handleCustomEvent = function (event) {
            (0, chat_container_utils_1.deleteScrollFunctions)();
            var jumpedData = event && event.detail;
            if (jumpedData && jumpedData.length > 0) {
                (0, chat_container_utils_1.triggerElement)(jumpedData[jumpedData.length - 1]._id.toString(), loadMore(jumpedData[jumpedData.length - 1]._id.toString()));
                (0, chat_container_utils_1.triggerElement)(jumpedData[0]._id.toString(), loadPrevious(jumpedData[0]._id.toString()));
            }
        };
        window.addEventListener('jumped', handleCustomEvent);
        return function () {
            window.removeEventListener('jumped', handleCustomEvent);
        };
    }, []);
    // handleScroll
    (0, react_1.useEffect)(function () {
        var handleScroll = function () {
            if (loadingMore || !scrollableDivRef.current || goingToParent || isFetching || !chatloaded)
                return;
            (0, chat_container_utils_1.toggleBottomScrollBtn)(scrollableDivRef.current);
            for (var key in chat_container_utils_1.scrollFunctions) {
                var scrollFunction = chat_container_utils_1.scrollFunctions[key];
                var element = document.getElementById("msg_".concat(key));
                if ((0, chat_container_utils_1.isElementInView)(scrollableDivRef.current, element) && hasMore) {
                    setIsFetching(true);
                    scrollFunction();
                }
            }
        };
        if (scrollableDivRef.current) {
            scrollableDivRef.current.addEventListener('scroll', handleScroll);
        }
        return function () {
            if (scrollableDivRef.current) {
                scrollableDivRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [loadingMore, goingToParent, isFetching, hasMore, chatloaded]);
    // angularChild and initialCommentsData
    (0, react_1.useEffect)(function () {
        var container = scrollableDivRef.current;
        // console.log('angularChild', angularChild)
        if (container && angularChild) {
            container.appendChild(angularChild);
            new ResizeObserver(function () {
                (0, chat_container_utils_1.scrollToElement)(container, 'bottom');
            }).observe(container);
        }
        if (initialCommentsData && initialCommentsData.length > 0 && container) {
            var parentFiltered = initialCommentsData.filter(function (comment) { return !comment.isParent; }) || [];
            var initialBeforeMessage = parentFiltered[parentFiltered.length - 1];
            (0, chat_container_utils_1.triggerElement)(initialBeforeMessage._id, loadMore(initialBeforeMessage._id));
        }
    }, [angularChild, initialCommentsData]);
    // delete scrollFunctions on channel select
    (0, react_1.useEffect)(function () {
        (0, chat_container_utils_1.deleteScrollFunctions)();
    }, [selectedChannel]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [isActivityPage && selectedChannel && ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-absolute tw-z-50 tw-flex tw-w-full tw-items-center tw-gap-x-2 tw-border-b tw-border-solid tw-border-divider-lines-1 tw-bg-background-5 tw-px-5 tw-py-4 tw-text-base tw-font-bold tw-text-text-3', children: [(0, jsx_runtime_1.jsx)(molecules_1.ChannelIcon, { type: selectedChannel.channelType, isPrivate: selectedChannel.privateChannel }), (0, jsx_runtime_1.jsx)("div", { children: selectedChannel.title })] })), (0, jsx_runtime_1.jsx)("div", { ref: scrollableDivRef, className: 'forum-message-container message-container scrollme' })] }));
};
// @ts-ignore
(0, angular_util_1.angularizeChildren)(ChatContainer, 'chatContainer', angular.module('live-trader'), {
    mCount: '<',
    loadingMore: '<',
    chatloaded: '<',
    lastAppendedComment: '<',
    goingToParent: '<',
    commentsData: '<',
    initialCommentsData: '<',
    selectedChannel: '<',
    isActivityPage: '<',
});
