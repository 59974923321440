"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Paragon_1 = require("@/lib/constants/Paragon");
var connect_1 = require("@useparagon/connect");
var react_1 = require("react");
if (typeof window !== 'undefined') {
    // @ts-ignore
    window.paragon = connect_1.paragon;
}
function useParagon(paragonUserToken) {
    var _a = __read((0, react_1.useState)(connect_1.paragon.getUser()), 2), user = _a[0], setUser = _a[1];
    var _b = __read((0, react_1.useState)(), 2), error = _b[0], setError = _b[1];
    var updateUser = (0, react_1.useCallback)(function () {
        var authedUser = connect_1.paragon.getUser();
        if (authedUser.authenticated) {
            setUser(__assign({}, authedUser));
        }
    }, []);
    // Listen for account state changes
    (0, react_1.useEffect)(function () {
        connect_1.paragon.subscribe(connect_1.SDK_EVENT.ON_INTEGRATION_INSTALL, updateUser);
        connect_1.paragon.subscribe(connect_1.SDK_EVENT.ON_INTEGRATION_UNINSTALL, updateUser);
        return function () {
            connect_1.paragon.unsubscribe(connect_1.SDK_EVENT.ON_INTEGRATION_INSTALL, updateUser);
            connect_1.paragon.unsubscribe(connect_1.SDK_EVENT.ON_INTEGRATION_UNINSTALL, updateUser);
        };
    }, []);
    (0, react_1.useEffect)(function () {
        if (!error && paragonUserToken) {
            connect_1.paragon
                .authenticate(Paragon_1.NEXT_PUBLIC_PARAGON_PROJECT_ID, paragonUserToken)
                .then(function () {
                var authedUser = connect_1.paragon.getUser();
                if (authedUser.authenticated) {
                    setUser(authedUser);
                }
            })
                .catch(setError);
        }
    }, [error, paragonUserToken]);
    return {
        paragon: connect_1.paragon,
        user: user,
        error: error,
        updateUser: updateUser,
    };
}
exports.default = useParagon;
