"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
function cn() {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(Boolean).join(' ');
}
/**
 * Custom BlurImage without `base64`
 */
function CustomBlurImage(_a) {
    var src = _a.src, alt = _a.alt, fill = _a.fill, onClick = _a.onClick, className = _a.className, width = _a.width, height = _a.height, props = __rest(_a, ["src", "alt", "fill", "onClick", "className", "width", "height"]);
    var _b = __read((0, react_1.useState)(true), 2), isLoading = _b[0], setLoading = _b[1];
    var setLoadingFalse = function () {
        setLoading(false);
    };
    (0, react_1.useEffect)(function () {
        var _a;
        (_a = document.getElementById('react-custom-blur-image')) === null || _a === void 0 ? void 0 : _a.addEventListener('load', setLoadingFalse);
        return function () {
            var _a;
            (_a = document.getElementById('react-custom-blur-image')) === null || _a === void 0 ? void 0 : _a.removeEventListener('load', setLoadingFalse);
        };
    });
    return ((0, jsx_runtime_1.jsx)("img", __assign({ id: 'react-custom-blur-image' }, props, { onClick: onClick, src: src, width: width, height: height, className: cn('tw-blur-[2px] tw-transition-all tw-duration-700 tw-ease-in-out', isLoading ? 'tw-blur-[2px]' : 'tw-blur-0', className), alt: alt })));
}
exports.default = CustomBlurImage;
