"use strict";
/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accordion = exports.AccordionContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
exports.AccordionContext = (0, react_1.createContext)({
    accordionRef: null,
    items: {},
    setItems: function (data) { },
    transition: null,
    alwaysOpen: false,
});
/**
 * General Accordion Component for General Usage
 * @example Example ussage can be found at `<GuideAccordion/>`
 */
var Accordion = function (_a) {
    var children = _a.children, _b = _a.as, as = _b === void 0 ? 'div' : _b, _c = _a.className, className = _c === void 0 ? '' : _c, _d = _a.transition, transition = _d === void 0 ? undefined : _d, _e = _a.alwaysOpen, alwaysOpen = _e === void 0 ? false : _e;
    var accordionRef = (0, react_1.useRef)(null);
    var _f = __read((0, react_1.useState)({}), 2), items = _f[0], setItems = _f[1];
    var TagName = (0, react_1.useMemo)(function () {
        if (as) {
            return as;
        }
        return 'div';
    }, [as]);
    var value = (0, react_1.useMemo)(function () {
        return {
            accordionRef: accordionRef,
            items: items,
            setItems: setItems,
            transition: transition,
            alwaysOpen: alwaysOpen,
        };
    }, [alwaysOpen, items, transition]);
    return ((0, jsx_runtime_1.jsx)(exports.AccordionContext.Provider, { value: value, children: (0, jsx_runtime_1.jsx)(TagName, { className: className, children: children }) }));
};
exports.Accordion = Accordion;
exports.default = exports.Accordion;
