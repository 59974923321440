"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
/**
 * Animated Navigation Active Background Component using Framer motion
 * @description This component will be used for active background state in navigations (example: Community setting navigation)
 * @example
 * {pathname.includes(sub.link) ? <NavigationActiveBackground /> : null}
 */
function NavigationActiveBackground() {
    var _a = __read((0, react_1.useState)(false), 2), isMounted = _a[0], setIsMounted = _a[1];
    (0, react_1.useEffect)(function () {
        setIsMounted(true);
    }, []);
    return (isMounted && ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { className: 'tw-absolute tw-bottom-0 tw-left-0 -tw-z-10 tw-h-full !tw-w-full tw-rounded-md tw-bg-accent-1 tw-text-background-1', layoutId: 'navbar', "aria-hidden": 'true', style: {
            width: '100%',
        }, transition: {
            type: 'tween',
            bounce: 0.25,
            stiffness: 130,
            damping: 9,
            duration: 0.3,
        } })));
}
exports.default = (0, react_1.memo)(NavigationActiveBackground);
