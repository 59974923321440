"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppDrawer = void 0;
var react_1 = require("react");
var general_hooks_1 = require("./general-hooks");
/** App Drawer
 *
 * This is used to keep track of the active drawer in the app especially for mobile navigation
 *
 * The active drawer value is stored in the window object to access it globally from both Angular and React
 *
 * @example window.activeDrawer = AppDrawer.AccountSettings
 *
 */
var AppDrawer;
(function (AppDrawer) {
    AppDrawer["AccountSettings"] = "account-settings";
    AppDrawer["Socials"] = "socials";
    AppDrawer["None"] = "none";
})(AppDrawer || (exports.AppDrawer = AppDrawer = {}));
/**
 * `useDrawer` Hook
 *
 * Manages the state of a React drawer from an Angular controller. For this to work, the React application must be wrapped with `rerenderHelper`.
 *
 * - `drawer`: The specific drawer to manage.
 *
 * ## Returns
 *
 * - `[open, setOpen]`: A tuple where `open` is a boolean indicating the drawer's visibility, and `setOpen` is a function to toggle this state.
 *
 * ## Usage
 *
 * ### In Angular:
 *
 * To open a drawer, set `window.activeDrawer` to the desired drawer's name and call `window.rerender()` to trigger a rerender in the React application.
 *
 * ```javascript
 * window.activeDrawer = 'account-settings';
 * window.rerender();
 * ```
 *
 * ### In React:
 *
 * Use the `useDrawer` hook to manage the drawer's state. Pass the drawer identifier to the hook and use the returned state and setter function to control the drawer's visibility.
 *
 * ```javascript
 *
 * import useDrawer, { AppDrawer } from '@/lib/hooks/use-drawer'
 *
 * const [drawerOpen, setDrawerOpen] = useDrawer({ drawer: AppDrawer.AccountSettings });
 * ```
 *
 */
var useDrawer = function (_a) {
    var drawer = _a.drawer;
    var _b = __read((0, react_1.useState)(false), 2), open = _b[0], setOpen = _b[1];
    var width = (0, general_hooks_1.useWindowSize)().width;
    if (!drawer) {
        throw new Error('Drawer must be defined to use useDrawer');
    }
    (0, react_1.useEffect)(function () {
        if (width < 770 && window.activeDrawer === drawer) {
            setOpen(true);
        }
    }, [window.activeDrawer]);
    var onChange = function (open) {
        setOpen(open);
        if (open) {
            window.activeDrawer = drawer;
        }
        else {
            window.activeDrawer = AppDrawer.None;
        }
    };
    return [open, onChange];
};
exports.default = useDrawer;
