"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var table_1 = require("@/components/ui/table");
var utils_1 = require("@/lib/utils");
var react_table_1 = require("@tanstack/react-table");
var lucide_react_1 = require("lucide-react");
var React = __importStar(require("react"));
/**
 * DataTable Component
 * @description Example usage can be found at `LoginAPITable.tsx` or `DataTable.mdx`
 * @link [Reference](https://ui.shadcn.com/docs/components/data-table)
 * @link [Used Library](https://tanstack.com/table/v8/docs/adapters/react-table)
 */
function DataTable(_a) {
    var _b;
    var columns = _a.columns, data = _a.data, customizeBody = _a.customizeBody, className = _a.className, tableHeaderClassName = _a.tableHeaderClassName, stickyHeader = _a.stickyHeader, tableBodyClassName = _a.tableBodyClassName, containerHeight = _a.containerHeight, noResultText = _a.noResultText;
    var _c = __read(React.useState([]), 2), sorting = _c[0], setSorting = _c[1];
    var table = (0, react_table_1.useReactTable)({
        data: data,
        columns: columns,
        onSortingChange: setSorting,
        getCoreRowModel: (0, react_table_1.getCoreRowModel)(),
        getSortedRowModel: (0, react_table_1.getSortedRowModel)(),
        getFilteredRowModel: (0, react_table_1.getFilteredRowModel)(),
        // getPaginationRowModel: getPaginationRowModel(),
        // onColumnVisibilityChange: setColumnVisibility,
        state: {
            sorting: sorting,
        },
    });
    return ((0, jsx_runtime_1.jsx)("div", { className: 'tw-w-full', children: (0, jsx_runtime_1.jsx)("div", { id: 'table-section', children: (0, jsx_runtime_1.jsxs)(table_1.Table, { className: className, containerHeight: containerHeight, children: [(0, jsx_runtime_1.jsx)(table_1.TableHeader, { className: (0, utils_1.cn)(stickyHeader ? 'tw-sticky tw-left-0 tw-top-0 tw-z-20' : ''), children: table.getHeaderGroups().map(function (headerGroup) { return ((0, jsx_runtime_1.jsx)(table_1.TableRow, { className: (0, utils_1.cn)('tw-hover:bg-transparent tw-border-b-0', tableHeaderClassName), children: headerGroup.headers.map(function (header) {
                                var _a = __read(React.useState(false), 2), sorted = _a[0], setSorted = _a[1];
                                return ((0, jsx_runtime_1.jsx)(table_1.TableHead, { children: (0, jsx_runtime_1.jsxs)("div", { "aria-hidden": 'true', onClick: function () {
                                            setSorted(function (prev) { return !prev; });
                                            header.column.toggleSorting(header.column.getIsSorted() === 'asc');
                                        }, className: (0, utils_1.cn)('tw-flex tw-items-center tw-gap-2', header.column.columnDef.enableSorting ? 'tw-cursor-pointer' : 'tw-cursor-default'), children: [header.isPlaceholder ? null : (0, react_table_1.flexRender)(header.column.columnDef.header, header.getContext()), header.column.columnDef.enableSorting ? ((0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.cn)('tw-mt-0.5 tw-transform-gpu tw-transition-all tw-duration-300 tw-ease-in-out', sorted ? 'tw-rotate-180' : 'tw-rotate-0'), children: (0, jsx_runtime_1.jsx)(lucide_react_1.ChevronDown, { width: '16px', height: '16px' }) })) : null] }) }, header.id));
                            }) }, headerGroup.id)); }) }), (0, jsx_runtime_1.jsx)(table_1.TableBody, { children: ((_b = table.getRowModel().rows) === null || _b === void 0 ? void 0 : _b.length) ? (table.getRowModel().rows.map(function (row) { return ((0, jsx_runtime_1.jsx)(table_1.TableRow, { "data-state": row.getIsSelected() && 'selected', className: tableBodyClassName, children: row.getVisibleCells().map(function (cell) { return ((0, jsx_runtime_1.jsx)(table_1.TableCell, { className: customizeBody ? 'tw-font-semibold tw-text-text-4' : '', children: (0, react_table_1.flexRender)(cell.column.columnDef.cell, cell.getContext()) }, cell.id)); }) }, row.id)); })) : ((0, jsx_runtime_1.jsx)(table_1.TableRow, { children: (0, jsx_runtime_1.jsx)(table_1.TableCell, { colSpan: columns.length, className: 'tw-h-24 tw-text-center', children: noResultText || 'No results.' }) })) })] }) }) }));
}
exports.default = DataTable;
